import React from "react";
import { Container, Row, Col, Image, Carousel, CarouselItem, CarouselCaption, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import SharedImage from "../../../assets/hospcpanel.svg";
import PleskImage from "../../../assets/hospplesk.svg";

const ListHospedagem = [
    { icon: <Icon.CheckSquareFill />, name: 'Olhe como um profissional! Rápido, seguro e sempre ativo.' },
    { icon: <Icon.CheckSquareFill />, name: 'Instalação instantânea do WordPress.' },
    { icon: <Icon.CheckSquareFill />, name: 'Tempo de atividade garantido de 99,9%' },
    { icon: <Icon.CheckSquareFill />, name: 'Let`s Encrypt SSL Gratuito.' },
    { icon: <Icon.CheckSquareFill />, name: 'Servidores Dell Enterprise SSD mais rápidos.' }
];

const ListHospedagemPlesk = [
    { icon: <Icon.CheckSquareFill />, name: 'Criado sob medida para cargas' },
    { icon: <Icon.CheckSquareFill />, name: 'Integração direta com GitHub' },
    { icon: <Icon.CheckSquareFill />, name: 'Tempo de atividade garantido de 99,9%' },
    { icon: <Icon.CheckSquareFill />, name: 'Recursos avançados de segurança' },
    { icon: <Icon.CheckSquareFill />, name: 'Servidores Dell Enterprise SSD mais rápidos.' }
];

export default function Header() {
    return(
        <>
            <div style={{height: '104px'}}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container>
                    <Carousel indicators>
                        <CarouselItem interval={ 2500 }>
                            <Row className="homecarousel row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <h2 className="text-uppercase fw-bold">Hospedagem cPanel</h2>
                                        <p className="col-md-10">Hospedagem de sites compartilhada fácil e acessível. Escolha uma solução de serviço de hospedagem na web ajustada para sites pessoais e comerciais de sucesso.</p>
                                        <ListGroup>
                                            { ListHospedagem.map((item, index) => (
                                                <ListGroupItem>
                                                    { item.icon } { item.name }
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                        <Button variant="outline-light" href="/hospedagem-cpanel" className="btn-verplanos mt-3">Ver Planos</Button>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={SharedImage} width={500} />
                                </Col>
                            </Row>
                        </CarouselItem>
                        <CarouselItem interval={ 2500 }>
                            <Row className="homecarousel row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <h2 className="text-uppercase fw-bold">Hospedagem Plesk Empresarial</h2>
                                        <p className="col-md-10">Alojamento de websites em servidores exclusivos da Hostop. Uma solução robusta com integração premium contra ataques DDoS.</p>
                                        <ListGroup>
                                            { ListHospedagemPlesk.map((item, index) => (
                                                <ListGroupItem>
                                                    { item.icon } { item.name }
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                        <Button variant="outline-light" href="/hospedagem-plesk" className="btn-verplanos mt-3">Ver Planos</Button>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={PleskImage} width={500} />
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
        </>
    )
}