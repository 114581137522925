import React from "react";
import { Card, CardBody, Container, Row, Col, Image, Button, Carousel, CarouselItem, CarouselCaption, ListGroup, ListGroupItem, CardHeader, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

// Images
import Hosting from "../../../assets/icon-shared-hosting.svg";
import Server from "../../../assets/icon-vps-hosting.svg";
import SharedImage from "../../../assets/vps-hostop-ultra.svg";
import SharedHosp from "../../../assets/shared-hosting-hostop.svg";

const Produtos = [
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=3', compromisso:'Sem compromissos ou contratos.' ,icon: SharedHosp, style: 'd-none', border: '', bordername: 'Mais Popular', name: 'VPS Ultra-10', description: 'Criado para pequenas empresas.', value: '102,00' },
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=3', compromisso:'Sem compromissos ou contratos.' ,icon: Hosting, style: 'd-block', border: 'border-outline', bordername: 'Mais Popular', name: 'VPS Ultra-11', description: 'Para sites com uso intensivo de recursos.', value: '205,00' },
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=3', compromisso:'Sem compromissos ou contratos.' ,icon: Server, style: 'd-none', border: '', bordername: 'A POTÊNCIA QUE VOCÊ PRECISA!', name: 'VPS Ultra-12', description: 'Para sites com uso intensivo de recursos.', value: '310,00' }
];

const ListHospedagem = [
    [
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "2", fim: "", name: "Núcleos de vCPU", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "4 GB", fim: "", name: "RAM", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "45 GB", fim: "", name: "Armazenamento Ultra SSD", display: "d-block", tooltip: "É possível adquirir armazenamento adicional" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "∞ Tráfego", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "4 TB", fim: "", name: "de Largura de Banda", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Windows", name: "Sistema Operacional", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Dedicado", name: "IP", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "Suporte 24/7", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "99.9%", fim: "", name: "Uptime Garantido", display: "d-none", tooltip: "" }
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "4", fim: "", name: "Núcleos De vCPU", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "6 GB", fim: "", name: "RAM", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "60 GB", fim: "", name: "Armazenamento Ultra SSD", display: "d-block", tooltip: "É possível adquirir armazenamento adicional" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "∞ Tráfego", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "8 TB", fim: "", name: "de Largura de Banda", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Windows", name: "Sistema Operacional", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Dedicado", name: "IP", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "Suporte 24/7", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "99.9%", fim: "", name: "Uptime Garantido", display: "d-none", tooltip: "" }
      ], [
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "6", fim: "", name: "Núcleos De vCPU", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "8 GB", fim: "", name: "Memória RAM", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "80 GB", fim: "", name: "Armazenamento Ultra SSD", display: "d-block", tooltip: "É possível adquirir armazenamento adicional" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "∞ Tráfego", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "16 TB", fim: "", name: "de Largura de Banda", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Windows", name: "Sistema Operacional", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Dedicado", name: "IP", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "Suporte 24/7", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá", display: "d-none", tooltip: "" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "99.9%", fim: "", name: "Uptime Garantido", display: "d-none", tooltip: "" }
      ],
  ];

const TableVPS = [
    { name: 'Modelo - Nome', classe: 'ocultar-cl' },
    { name: 'CPU', classe: '' },
    { name: 'Memória', classe: '' },
    { name: 'Armazenar', classe: '' },
    { name: 'Largura de Banda', classe: 'ocultar-cl' },
    { name: 'Preço', classe: '' },
    { name: '' }
]

const TableItem = [
    { name: 'VPS Ultra-13', cpu: '8vCPU', memoria: '12GB', armazenar: '250GB', largura: 'Ilimitada', price: '430,00', link: '' }
]

export default function Servidor() {

    return(
        <>
            <div style={{height: '104px'}}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container className="productCarousel">
                    <Carousel>
                        <CarouselItem interval={ 3000 }>
                            <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <p className="col-md-10 mn-1">HOSPEDAGEM VPS DE ÚLTIMA GERAÇÃO</p>
                                        <h1 className="text-uppercase fw-bold">SERVIDOR VPS</h1>
                                        <p className="col-md-10">Obtenha sem esforço a potência e a flexibilidade que você precisa. Nossos servidores virtuais privados são construídos do zero usando todo o armazenamento SSD. Aproveite maior poder, flexibilidade e controle para seus sites.</p>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={SharedImage} width={350} />
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Planos e preços de hospedagem VPS</h2>
                    <h5 className="fw-normal text-secondary">Hospedagem VPS autogerenciada – perfeita para administradores de sistema e desenvolvedores.</h5>
                </div>
                <Row className="products-h">
                {Produtos.map((item, index) => (
                    <Col key={index} className="col-md-4 text-center mb-5">
                    <Card className={item.border}>
                        <CardBody>
                        <span className={`plan-title ${item.style}`}>{item.bordername}</span>
                        <Image src={item.icon} width={"50"} />
                        <h4 className="pt-3 fw-semibold">{item.name}</h4>
                        <h6 className="pt-2 text-black-50">{item.description}</h6>
                        <h7 className="pt-4 mb-3">A partir de:</h7>
                        <h3 className="fw-color-blue fw-regular fs-7 mb-3">
                            <b className="fs-2">R$ {item.value}</b>/mês
                        </h3>
                        <div className="fw-bt-outline fw-color-blue">{item.compromisso}</div>
                        <ListGroup className="mt-3 text-start">
                            {ListHospedagem[index].map((subItem, subIndex) => (
                            <ListGroupItem key={subIndex} className="color-listgroup">
                                {subItem.icon} <b className="text-black">{subItem.inicio}</b> {subItem.name} <b className="text-black">{subItem.fim}</b>
                                <OverlayTrigger key={subIndex} placement="right" overlay={<Tooltip id={`tooltip-${subIndex}`}>{subItem.tooltip}</Tooltip>}>
                                    <button className={`popover-btn ${subItem.display}`}>?</button>
                                </OverlayTrigger>
                            </ListGroupItem>
                            ))}
                        </ListGroup>

                        <Button className="button-outline fw-semibold mt-4" href={item.link}>Compre agora</Button>
                        </CardBody>
                    </Card>
                    </Col>
                ))}
                </Row>
            </Container>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Quando você espera desempenho</h2>
                    <h5 className="fw-normal text-secondary">Máquinas virtuais com um equilíbrio saudável de memória e hyper-threads de computação dedicados dos melhores processadores da categoria. Projetado para a mais ampla variedade de cargas de trabalho convencionais ou de produção, incluindo hospedagem de aplicativos Web, sites de comércio eletrônico, bancos de dados de médio porte e aplicativos empresariais.</h5>
                </div>
                <Row>
                    <Card className="p-0">
                        <CardHeader className="card-header-servidor">
                            <h5 className="fw-semibold">Hardware aprimorado para nós empresariais da Dell</h5>
                            <p className="m-0">Todo o nosso hardware é Dell Enterprise com armazenamento raid10, redundância dupla e energia, rede dupla redundância.</p>
                        </CardHeader>
                        <CardBody className="p-0">
                            <Table className="m-0" responsive="sm">
                                <thead>
                                <tr>
                                     {TableVPS.map((item, index) => (
                                        <th className={`bg-primarys text-white ${item.classe}`}>{item.name}</th>
                                     ))}
                                </tr>
                                </thead>
                                <tbody className="tablebody">
                                {TableItem.map((item, index) => (
                                    <tr>
                                        <>
                                            <td className="ocultar-cl">{item.name}</td>
                                            <td>{item.cpu}</td>
                                            <td>{item.memoria}</td>
                                            <td>{item.armazenar}</td>
                                            <td className="ocultar-cl">{item.largura}</td>
                                            <td className="price-td fw-semibold">R$ {item.price}<span className="text-secondary fw-normal fs-7">/mês</span></td>
                                            <td>
                                                <Button className="button-buy" href={item.link}>Peça agora</Button>
                                            </td>
                                        </>
                                    </tr>
                                 ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <div className="bg-secondary-n p-1">
                <Container className="mt-4 mb-4">
                    <Row>
                        <Col md={7} className="d-flex align-items-center">
                            <h5 className="text-white fw-bolder m-0">Hospedagem confiável, segura, altamente disponível e descomplicada</h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="outline-light" href="/hospedagem">Inscreva-se agora</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}