import React from "react"
import { Col, Container, Row, Image } from "react-bootstrap"
import Marquee from "react-fast-marquee";

// Imagem
import cPanel from "../../../assets/cpanel.svg";
import CloudFlare from "../../../assets/cloudflare.svg";
import JetBackup from "../../../assets/jetbackup.svg";
import Imunify from "../../../assets/imunify360.svg";
import CloudLinux from "../../../assets/cloudlinux.svg";
import LiteSpeed from "../../../assets/litespeed.svg";
import SitePad from "../../../assets/sitepad.svg";
import LetsEncrypt from "../../../assets/letsencrypt.svg";
import Softaculous from "../../../assets/softaculous.svg";
import Wordpress from "../../../assets/wordpress.svg";
import Windows from "../../../assets/windows.svg";
import Linux from "../../../assets/linux.svg";
import Plesk from "../../../assets/plesk.svg";
import Virtualizor from "../../../assets/virtualizor.png";

const Primary = [
    { name: 'cPanel', icon: cPanel },
    { name: 'Plesk', icon: Plesk },
    { name: 'CloudFlare', icon: CloudFlare },
    { name: 'JetBackup', icon: JetBackup },
    { name: 'Imunify360', icon: Imunify },
    { name: 'CloudLinux', icon: CloudLinux },
    { name: 'LiteSpeed', icon: LiteSpeed },
];

const Secondary = [
    { name: 'SitePad', icon: SitePad },
    { name: 'LetsEncrypt', icon: LetsEncrypt },
    { name: 'Softaculous', icon: Softaculous },
    { name: 'Wordpress', icon: Wordpress },
    { name: 'Windows', icon: Windows },
    { name: 'Linux', icon: Linux },
    { name: 'Virtualizor', icon: Virtualizor }
];

export default function Vendors() {
    return(
        <>
            <Container className="mt-5 mb-5">
                <Row className="pt-5 pb-5 d-flex justify-content-center align-items-center">
                    <Col className="col-md-4">
                        <h3 className="fw-bold">Tecnologias <code>&</code> Vendors</h3>
                        <p className="text-black-50">Eleve o nível do seu projeto online com as melhores tecnologias.</p>
                    </Col>
                    <Col className="col-md-auto col-md-8 d-flex flex-wrap">
                        <Marquee direction="left" gradient={true} pauseOnHover={true}>
                            {Primary.map((item, index) => (
                                <div className="imageVendors">
                                    <Image src={item.icon} alt={item.name} />
                                </div>
                            ))}
                        </Marquee>
                        <Marquee direction="right" gradient={true} pauseOnHover={true}>
                            {Secondary.map((item, index) => (
                                <div className="imageVendors">
                                    <Image src={item.icon} alt={item.name} />
                                </div>
                            ))}
                        </Marquee>
                    </Col>
                </Row>
            </Container>
        </>
    )
}