import React from "react";
import {  Card, CardBody, Container, Row, Col, Image, Button, Carousel, CarouselItem, CarouselCaption, ListGroup, ListGroupItem } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

// Images
import Hosting from "../../../assets/icon-shared-hosting.svg";
import Server from "../../../assets/icon-vps-hosting.svg";
import SharedImage from "../../../assets/advanced-ddos-item-4.svg?1";
import SharedHosp from "../../../assets/shared-hosting-hostop.svg";

import ProtecaoImage from "../../../assets/layerprotecao.svg";

const RecursosItem = [
    [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Validação de protocolo IPv4" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Bloqueio de amplificações" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Regras de entrada (allow/deny)" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Allow IP list (API)" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Deny IP list (API)" }
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Validação do payload" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Inspeção por RegEx" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Padronização de tráfego" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Validação por algorítimo" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Monitoramento do tráfego" }
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por IP/Site" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por Porta" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por Protocolo" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por País (GEOIP)" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por Tamanho (MTU)" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por Tempo de Vida (TTL)" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Rate limiting por Payload" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Análise do fluxo (24 horas)" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Monitoramento proativo de host" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Gestão das listas de acesso" },
    ]
]

const Produtos = [
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=7', compromisso:'Sem compromissos ou contratos.' ,icon: SharedHosp, style: 'd-none', border: '', bordername: 'Mais Popular', name: 'Proteção Básica', description: 'Proteção básica para o seu site', value: '1.500,00' },
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=7', compromisso:'Sem compromissos ou contratos.' ,icon: Hosting, style: 'd-block', border: 'border-outline', bordername: 'Mais Popular', name: 'Proteção Média', description: 'Proteção ideal para o seu negócio', value: '3.500,00' },
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=7', compromisso:'Sem compromissos ou contratos.' ,icon: Server, style: 'd-none', border: '', bordername: 'A POTÊNCIA QUE VOCÊ PRECISA!', name: 'Proteção Avançada', description: 'Proteção avançada para a sua empresa', value: '6.000,00' }
];

const ListHospedagem = [
    [
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "1GBPS", name: "Link de Rede" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "99.99%", name: "Uptime" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Nuvem", name: "Servidor na" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Layer 7", name: "Proteção DDOS contra" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "In-Home", name: "Equipamento de Proteção" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "TCP e UDP", name: "Proteção à Servidores de Jogos" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "Proteção à Websites, WordPress, Fórum" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Chat Online", name: "Proteção à" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Internet", name: "Proteção à serviços conectados à" },
        
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "5GBPS", name: "Link de Rede" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "99.99%", name: "Uptime" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Nuvem", name: "Servidor na" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Layer 7", name: "Proteção DDOS contra" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "In-Home", name: "Equipamento de Proteção" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "TCP e UDP", name: "Proteção à Servidores de Jogos" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "Proteção à Websites, WordPress, Fórum" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Chat Online", name: "Proteção à" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Internet", name: "Proteção à serviços conectados à" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "Link de Rede" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "99.99%", name: "Uptime" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Nuvem", name: "Servidor na" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Layer 7", name: "Proteção DDOS contra" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "In-Home", name: "Equipamento de Proteção" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "TCP e UDP", name: "Proteção à Servidores de Jogos" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "Proteção à Websites, WordPress, Fórum" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Chat Online", name: "Proteção à" },
        { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Internet", name: "Proteção à serviços conectados à" },
    ]
  ];

export default function Protection() {
    return(
        <>
            <div style={{height: '104px'}}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container className="productCarousel">
                    <Carousel>
                        <CarouselItem interval={ 3000 }>
                            <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <p className="col-md-10 mn-1">PROTEÇÃO DDOS PREMIUM DISPONÍVEL</p>
                                        <h1 className="text-uppercase fw-bold">Proteção DDOS Premium</h1>
                                        <p className="col-md-10">Sites e aplicações web estão a ser cada vez mais atacados, sem qualquer distinção. Para prevenir as ameaças mais comuns à segurança do seu site, a Hostop oferece-lhe serviços de proteção.</p>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={SharedImage} width={200} />
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Escolha o plano de Proteção DDOS Premium.</h2>
                    <h5 className="fw-normal text-secondary">Mantenha sua empresa online e funcionando!</h5>
                </div>
                <Row className="products-h">
                {Produtos.map((item, index) => (
                    <Col key={index} className="col-md-4 text-center mb-5">
                    <Card className={item.border}>
                        <CardBody>
                        <span className={`plan-title ${item.style}`}>{item.bordername}</span>
                        <Image src={item.icon} width={"50"} />
                        <h4 className="pt-3 fw-semibold">{item.name}</h4>
                        <h6 className="pt-2 text-black-50">{item.description}</h6>
                        <h7 className="pt-4 mb-3">A partir de:</h7>
                        <h3 className="fw-color-blue fw-regular fs-7 mb-3">
                            <b className="fs-2">R$ {item.value}</b>/por site
                        </h3>
                        <div className="fw-bt-outline fw-color-blue">{item.compromisso}</div>
                        <ListGroup className="mt-3 text-start">
                            {ListHospedagem[index].map((subItem, subIndex) => (
                            <ListGroupItem key={subIndex} className="color-listgroup">
                                {subItem.icon} <b className="text-black">{subItem.inicio}</b> {subItem.name} <b className="text-black">{subItem.fim}</b>
                            </ListGroupItem>
                            ))}
                        </ListGroup>

                        <Button className="button-outline fw-semibold mt-4" href={item.link}>Compre agora</Button>
                        </CardBody>
                    </Card>
                    </Col>
                ))}
                </Row>
            </Container>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Proteção DDOS Premium</h2>
                    <h5 className="fw-normal text-secondary">A primeira linha de defesa para qualquer produto ou serviço. Uma incrível capacidade de rede e uma plataforma distribuída mundial protegem-no de ataques informáticos em larga escala.</h5>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col className="d-flex align-items-center justify-content-center">
                        <Image width={250} src={ProtecaoImage}/>
                    </Col>
                    <Col>
                        <h6 className="fw-semibold">O Anti-DDoS da Hostop é composto por:</h6>
                        <ul className="ullist p-0 mx-3 text-secondary">
                            <li>Capacidade superior a <b>17 Tbit/s</b> para a filtragem de ataques globais</li>
                            <li>Deteção contínua de ataques e mitigação rápida de tráfego malicioso</li>
                            <li>Ilimitado e sem custos adicionais, independentemente do volume de ataque</li>
                            <li>Proteção sem limite de tempo Dura ao longo de todo o ataque DDoS</li>
                        </ul>
                        <h6 className="fw-semibold">A nossa infraestrutura também beneficia de:</h6>
                        <ul className="ullist p-0 mx-3 text-secondary">
                            <li><b>Vasta experiência</b> na proteção de uma gama de serviços, de pequenos servidores web e serviços DNS a grandes web hosting farms ou plataformas cloud</li>
                            <li><b>Alto desempenho</b> graças às melhores soluções de hardware e software</li>
                            <li>Soberania de dados, para que o seu tráfego não seja partilhado com entidades externas</li>
                            <li><b>Verdadeira personalização</b>, para responder às suas necessidades e componentes de personalização</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            {/* // Recursos */}
            <Container className="mt-5 mb-5">
            <div className="text-center mt-5 mb-5">
                <h2 className="fw-bolder">Especificações técnicas completas</h2>
                <h5 className="fw-normal text-secondary">Recursos avançados à disposição.</h5>
            </div>
            <Row>
                {RecursosItem.map((recursos, index) => (
                <Col key={index} className="col-xl-3 col-lg-3 col-md-6">
                    <ListGroup className="mt-3 text-start">
                        {recursos.map((subItem, subIndex) => (
                            <ListGroupItem key={subIndex} className="color-listgroup">
                            {subItem.icon} {subItem.name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
                ))}
            </Row>
            </Container>
            <div className="bg-secondary-n p-1">
                <Container className="mt-4 mb-4">
                    <Row>
                        <Col md={7} className="d-flex align-items-center">
                            <h5 className="text-white fw-bolder m-0">Hospedagem confiável, segura, altamente disponível e descomplicada</h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="outline-light" href="/hospedagem">Inscreva-se agora</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}