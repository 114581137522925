import React from "react"
import { Card, CardBody, Col, Container, Row, Image } from "react-bootstrap"

// Images
import Support from "../../../assets/support-hostop.svg";
import Featured from "../../../assets/featured-hostop.svg";
import Uptime from "../../../assets/uptime-hostop.svg";
import DDOS from "../../../assets/ddos-hostop.svg";

const Hostop = [
    { name: 'Suporte 24/7', description: 'Garantimos que você tenha uma ótima experiência ao usar a Hostop.', icon: Support },
    { name: 'Fácil de Usar', description: 'Contamos com um Painel de Controle cPanel muito rápido, prático e fácil de utilizar.', icon: Featured },
    { name: '99.9% Uptime', description: 'Sua hospedagem e servidores estarão acessíveis e online 24 horas por dia.', icon: Uptime },
    { name: 'Proteção DDoS', description: 'Proteção DDoS automática, em tempo real e sempre ativa, sem tempo de inatividade.', icon: DDOS }
];


export default function Success() {
    return(
        <>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Eleve a sua presença online com a Hostop</h2>
                    <h5 className="fw-normal">A escolha profissional para hospedagem de sites.</h5>
                </div>
                <Row>
                    {Hostop.map((item, index) => (
                        <Col md={3} className="producthome mb-5">
                            <Card className="hoverCard">
                                <CardBody className="text-center">
                                    <Image className="mb-3"src={ item.icon } width={'50px'}/>
                                    <h5 className="fw-bolder">{ item.name }</h5>
                                    <h7>{ item.description }</h7>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}