import React from "react";
import { Image, Carousel, CarouselCaption, CarouselItem, Col, Container, Row } from "react-bootstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngExpression } from 'leaflet';

import SharedImage from "../../../assets/locationimg.svg";
import CustomMarkerIcon from "../../../assets/marker-icon.png";

export default function Datacenter() {
  const defaultCenter: LatLngExpression = [10, -60]; // Centro do mapa para mostrar os continentes
  const defaultZoom: number = 3; // Zoom para mostrar os continentes

  const markerPosition: LatLngExpression = [45.3167, -73.8662];
  const markerPositionBrazil: LatLngExpression = [-23.5505, -46.6333]; // São Paulo, Brasil
  const markerPositionUS: LatLngExpression = [28.5383, -81.3792]; // Orlando, Flórida, Estados Unidos

  const customMarkerIcon = new L.Icon({
    iconUrl: CustomMarkerIcon,
    iconSize: [25, 41],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
    className: 'blinking'
  });

  return (
    <>
      <div style={{ height: "104px" }}></div>
      <section>
        <div className="bgcontainer"></div>
        <Container className="productCarousel">
          <Carousel>
            <CarouselItem interval={3000}>
              <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                <Col className="col col-md-6 d-grid justify-content-center">
                  <CarouselCaption>
                    <h1 className="text-uppercase fw-bold">
                      Nossos Datacenters
                    </h1>
                    <p className="col-md-10">
                        Explore com precisão a localização geográfica estratégica e altamente segura de nossos datacenters de última geração, fundamentais para garantir a confiabilidade e a eficiência de nossos serviços.
                    </p>
                  </CarouselCaption>
                </Col>
                <Col className="d-flex justify-content-center">
                  <Image src={SharedImage} width={350} />
                </Col>
              </Row>
            </CarouselItem>
          </Carousel>
        </Container>
      </section>
      <Container className="mt-5 mb-5">
        <div className="text-center mt-5 mb-5">
          <div style={{ height: '650px', width: '100%' }}>
            <MapContainer center={defaultCenter} zoom={defaultZoom} style={{ height: '100%', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={markerPosition} icon={customMarkerIcon}>
                <Popup>
                  Datacenter Canadá - BHS8 - Beauharnoi
                </Popup>
              </Marker>
              <Marker position={markerPositionBrazil} icon={customMarkerIcon}>
                <Popup>
                  Datacenter Brasil - São Paulo
                </Popup>
              </Marker>
              <div className="bloop">
                <Marker position={markerPositionUS} icon={customMarkerIcon}>
                    <Popup>
                    Datacenter EUA - Flórida, Orlando
                    </Popup>
                </Marker>
              </div>
            </MapContainer>
          </div>
        </div>
        <Row className="products-h"></Row>
      </Container>
    </>
  );
}
