import React from "react";
import {  Card, CardBody, Container, Row, Col, Image, Button, Carousel, CarouselItem, CarouselCaption, ListGroup, ListGroupItem } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

// Images
import Hosting from "../../../assets/icon-shared-hosting.svg";
import Server from "../../../assets/icon-vps-hosting.svg";
import SharedImage from "../../../assets/hospcpanel.svg";
import SharedHosp from "../../../assets/shared-hosting-hostop.svg";

const Produtos = [
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=1', compromisso:'Sem compromissos ou contratos.' ,icon: SharedHosp, style: 'd-none', border: '', bordername: 'Mais Popular', name: 'Hospedagem Básica', description: 'Mais espaço e flexibilidade para o local inicial.', value: '26,00' },
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=1', compromisso:'Sem compromissos ou contratos.' ,icon: Hosting, style: 'd-block', border: 'border-outline', bordername: 'Mais Popular', name: 'Hospedagem Média', description: 'Mais potência para sites com tráfego intenso.', value: '35,00' },
    { link: 'https://financeiro.hostop.com.br/produtos.php?ID=1', compromisso:'Sem compromissos ou contratos.' ,icon: Server, style: 'd-none', border: '', bordername: 'A POTÊNCIA QUE VOCÊ PRECISA!', name: 'Hospedagem Avançada', description: 'Suporte máximo para vários sites complexos.', value: '51,00' }
];


const ListHospedagem = [
    [
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "10GB", fim: "", name: "Armazenamento Ultra SSD" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "4GB", fim: "", name: "Memória RAM" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "3.8 GHz", name: "Potência Da CPU" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "∞ Tráfego" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "SSL Grátis", fim: "", name: "- todos domínios hospedados" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "5", fim: "", name: "Domínios" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitadas", name: "Contas de E-mail" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Instalação WordPress", fim: "", name: "- apenas um clique" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Backup Automático", fim: "", name: "Gratuito" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Sites", name: "Construtor de" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "PHP 4.x à 8.x", name: "Versão" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "LiteSpeed", fim: "", name: "Cache Speed" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "NodeJS" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "MariaDB" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "99.9%", fim: "", name: "Uptime Garantido" }
    ], [
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "20GB", fim: "", name: "Armazenamento Ultra SSD" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "6GB", fim: "", name: "Memória RAM" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "3.8 GHz", name: "Potência Da CPU" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "∞ Tráfego" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "SSL Grátis", fim: "", name: "- todos domínios hospedados" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "15", fim: "", name: "Domínios" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitadas", name: "Contas de E-mail" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Instalação WordPress", fim: "", name: "- apenas um clique" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Backup Automático", fim: "", name: "Gratuito" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Sites", name: "Construtor de" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "PHP 4.x à 8.x", name: "Versão" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "LiteSpeed", fim: "", name: "Cache Speed" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "NodeJS" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "MariaDB" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "99.9%", fim: "", name: "Uptime Garantido" }
      ], [
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "50GB", fim: "", name: "Armazenamento Ultra SSD" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "8GB", fim: "", name: "Memória RAM" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "3.8 GHz", name: "Potência Da CPU" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitado", name: "∞ Tráfego" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "SSL Grátis", fim: "", name: "- todos domínios hospedados" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitados", name: "Domínios" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Ilimitadas", name: "Contas de E-mail" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Instalação WordPress", fim: "", name: "- apenas um clique" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Backup Automático", fim: "", name: "Gratuito" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "Sites", name: "Construtor de" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "PHP 4.x à 8.x", name: "Versão" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "LiteSpeed", fim: "", name: "Cache Speed" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "NodeJS" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "", fim: "", name: "MariaDB" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "Datacenter", fim: "", name: "Canadá" },
      { icon: <Icon.CheckLg className="iconCheck" />, inicio: "99.9%", fim: "", name: "Uptime Garantido" }
      ],
  ];

const RecursosItem = [
    [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Painel de controle cPanel" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Softaculous Premium" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "SO CloudLinux" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Servidor Web LiteSpeed" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Suporte PHP de 5.3 a 8.1" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Biblioteca Gráfica GD" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Carregador de guarda Zend" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Carregador PHP ionCube" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "ImagemMagick" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Bancos de dados MySQL ilimitados" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Editor de zona DNS" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Editor de troca de correio (MX)" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Terminal SSH cPanel" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Multi-PHP" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Plug-in de cache Litespeed" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Migração gerenciada gratuita" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Suporte cPanel ilimitado" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Cobertura de suporte 24 horas por dia" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Monitoramento proativo de host" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Construtor de sites gratuito SitePad" },
    ]
]

export default function Hospedagem() {
    return(
        <>
            <div style={{height: '104px'}}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container className="productCarousel">
                    <Carousel>
                        <CarouselItem interval={ 3000 }>
                            <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <p className="col-md-10 mn-1">SSD DE DESEMPENHO MAIS RÁPIDO</p>
                                        <h1 className="text-uppercase fw-bold">HOSPEDAGEM cPanel</h1>
                                        <p className="col-md-10">Hospedagem Web Rápida e Segura SSD Ultra, largura de banda ilimitada, hospedagem imbatível</p>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={SharedImage} width={350} />
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Planos de Hospedagem Compartilhada</h2>
                    <h5 className="fw-normal text-secondary">Utilize nossa poderosa tecnologia de ponta, incluindo rede 100% Juniper, CloudLinux Enterprise e Datacenter de classe mundial com várias conexões de rede GIGe Redundant. Garantimos que você tenha uma ótima experiência ao usar Hostop.</h5>
                </div>
                <Row className="products-h">
                {Produtos.map((item, index) => (
                    <Col key={index} className="col-md-4 text-center mb-5">
                    <Card className={item.border}>
                        <CardBody>
                        <span className={`plan-title ${item.style}`}>{item.bordername}</span>
                        <Image src={item.icon} width={"50"} />
                        <h4 className="pt-3 fw-semibold">{item.name}</h4>
                        <h6 className="pt-2 text-black-50">{item.description}</h6>
                        <h7 className="pt-4 mb-3">A partir de:</h7>
                        <h3 className="fw-color-blue fw-regular fs-7 mb-3">
                            <b className="fs-2">R$ {item.value}</b>/mês
                        </h3>
                        <div className="fw-bt-outline fw-color-blue">{item.compromisso}</div>
                        <ListGroup className="mt-3 text-start">
                            {ListHospedagem[index].map((subItem, subIndex) => (
                            <ListGroupItem key={subIndex} className="color-listgroup">
                                {subItem.icon} <b className="text-black">{subItem.inicio}</b> {subItem.name} <b className="text-black">{subItem.fim}</b>
                            </ListGroupItem>
                            ))}
                        </ListGroup>

                        <Button className="button-outline fw-semibold mt-4" href={item.link}>Compre agora</Button>
                        </CardBody>
                    </Card>
                    </Col>
                ))}
                </Row>
            </Container>
            {/* // Recursos */}
            <Container className="mt-5 mb-5">
            <div className="text-center mt-5 mb-5">
                <h2 className="fw-bolder">Especificações técnicas completas</h2>
                <h5 className="fw-normal text-secondary">Recursos avançados de hospedagem compartilhada.</h5>
            </div>
            <Row>
                {RecursosItem.map((recursos, index) => (
                <Col key={index} className="col-xl-3 col-lg-3 col-md-6">
                    <ListGroup className="mt-3 text-start">
                        {recursos.map((subItem, subIndex) => (
                            <ListGroupItem key={subIndex} className="color-listgroup">
                            {subItem.icon} {subItem.name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
                ))}
            </Row>
            </Container>
            <div className="bg-secondary-n p-1">
                <Container className="mt-4 mb-4">
                    <Row>
                        <Col md={7} className="d-flex align-items-center">
                            <h5 className="text-white fw-bolder m-0">Hospedagem confiável, segura, altamente disponível e descomplicada</h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="outline-light" href="#">Inscreva-se agora</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}