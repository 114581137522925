import React from "react"
import { Col, Container, Row, Button } from "react-bootstrap"

export default function Account() {
    return(
        <>
            <Container className="mt-4 mb-4">
                <Row>
                    <Col md={7} className="d-flex align-items-center">
                        <h5 className="text-white fw-bolder m-0">Hospedagem confiável, segura, altamente disponível e descomplicada</h5>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="outline-light" href="/hospedagem">Inscreva-se agora</Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}