import React from "react";
import { Card, CardBody, Image, Col, Container, Row, Button } from "react-bootstrap";

// Images
import Server from "../../../assets/icon-vps-hosting.svg";
import Hospedagem from "../../../assets/shared-hosting-menu.svg";
import Reseller from "../../../assets/reseller-hosting.svg";
import Dedicated from "../../../assets/vps-servers-hostop.svg";
import Protecao from "../../../assets/semi-dedicated-hostop.svg";
import RadioIcon from "../../../assets/iconeradio.svg";

const Products = [
    { link: '/hospedagem-cpanel', icon: Hospedagem, style: 'd-block', border: 'border-outline', bordername: 'Mais Popular', name: 'Hospedagem cPanel', description: 'Nossos planos são otimizados para lhe oferecer o melhor desempenho.', value: '26,00' },
    { link: '/', icon: RadioIcon, style: 'd-none', border: '', bordername: 'Mais Popular', name: 'Streaming de Rádio', description: 'Crie suas próprias playlists para que você faça a programação que desejar.', value: '15,00' },
    { link: '/servidores', icon: Server, style: 'd-none', border: '', bordername: 'A POTÊNCIA QUE VOCÊ PRECISA!', name: 'Servidores VPS', description: 'Servidor VPS autogerenciado, perfeito para administradores de sistemas.', value: '102,00' },
    { link: '/revenda', icon: Reseller, style: 'd-none', border: '', bordername: 'Mais Popular', name: 'Planos de Revendedor', description: 'Servidores ultrarrápidos, armazenamento SSD e rede extremamente rápida, contas cPanel ilimitadas.', value: '125,00' },
    { link: '/dedicados', icon: Dedicated, style: 'd-block', border: 'border-outline', bordername: 'A POTÊNCIA QUE VOCÊ PRECISA!', name: 'Servidores dedicados', description: 'Servidores dedicados Hostop garantem alto desempenho e confiabilidade com tecnologia avançada.', value: '550,00' },
    { link: '/protecao', icon: Protecao, style: 'd-none', border: '', bordername: 'Mais Popular', name: 'Proteção DDOS Premium', description: 'Aprimore segurança, resiliência e minimize a superfície de ataque, fornecedores e ferramentas.', value: '1.500,00' }
];

export default function Home() {
    return(
        <>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Temos uma solução de hospedagem para você</h2>
                    <h5 className="fw-normal">Escolha a melhor plataforma para suas necessidades.</h5>
                </div>
                <Row className="products-h">
                    {Products.map((item, index) => (
                        <Col key={index} className="col-md-4 text-center mb-5 producthome">
                            <Card className={item.border}>
                                <CardBody>
                                    <span className={`plan-title ${item.style}`}>{item.bordername}</span>
                                    <Image src={ item.icon } width={'50'} />
                                    <h4 className="pt-3 fw-semibold">{item.name}</h4>
                                    <h6 className="pt-2 text-black-50">{ item.description }</h6>
                                    <h7 className="pt-4 mb-3">A partir de: <b>R$ { item.value } /mês</b></h7>
                                    <Button className="button-outline" href={item.link}>Ver Detalhes</Button>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )
}