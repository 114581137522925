import React from "react";
import {  Card, CardBody, Container, Row, Col, Image, Button, Carousel, CarouselItem, CarouselCaption, ListGroup, ListGroupItem } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import SharedImage from "../../../assets/plesk.svg";
import Local from "../../../assets/flag_can.svg";

const RecursosItem = [
    [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Criado sob medida para cargas" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Integração direta com GitHub" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Tempo de atividade garantido de 99,9%" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Recursos avançados de segurança" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Servidores Dell Enterprise SSD" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Serviço de E-mail" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Tecnologia LiteSpeed LSPHP" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Plug-in LSCACHE disponível" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Cache de código de operação" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Memcached" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Versão PHP/MySQL mais recente e rápida" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "HTTP/2 e HTTP/3" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Monitoramento de Recursos" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Sistemas Anti-Hack e Ajuda" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Atualizações e patches proativos" },
    ], [
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Isolamento de conta CageFS" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Suporte ilimitado" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Escalabilidade integrada" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Monitoramento proativo de host" },
        { icon: <Icon.CheckLg className="iconCheck" />, name: "Firewall de aprendizado de máquina" },
    ]
]

export default function HospedagemPlesk() {
    return(
        <>
            <div style={{height: '104px'}}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container className="productCarousel">
                    <Carousel>
                        <CarouselItem interval={ 3000 }>
                            <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <h1 className="text-uppercase fw-bold">PLESK EMPRESARIAL</h1>
                                        <p className="col-md-10">Alojamento de websites em servidores exclusivos da Hostop. Uma solução robusta com integração premium contra ataques DDoS.</p>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <a href="/datacenter" className="certified d-flex flex-column align-items-center">
                                        <Image src={ Local } width="110" height="72" alt="Tier IV Canadá" />
                                        <div>
                                            <Icon.Award /> Certificado Tier IV Canadá
                                        </div>
                                    </a>
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
            <Container className="mt-5 mb-5">
                {/* <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Planos de Hospedagem Compartilhada</h2>
                    <h5 className="fw-normal text-secondary">Utilize nossa poderosa tecnologia de ponta, incluindo rede 100% Juniper, CloudLinux Enterprise e Datacenter de classe mundial com várias conexões de rede GIGe Redundant. Garantimos que você tenha uma ótima experiência ao usar Hostop.</h5>
                </div> */}
                <Row className="products-h">
                    <Col className="col-md-12 text-center mb-5">
                    <Card>
                        <CardBody>
                            <div className="d-flex gap-5">
                                <div className="icon col-md-2">
                                    <Image src={SharedImage} />
                                </div>
                                <div className="nameHosting col-md-6">
                                    <h5>Hospedagem Plesk Empresarial</h5>
                                    <h6>
                                        <p>Adquira a Hospedagem Plesk Empresarial para uma solução personalizada. Ao solicitar um orçamento, nossa equipe especializada ajustará a oferta às suas expectativas.</p>
                                        <p>Com nossa experiência em hospedagem e os recursos avançados do Plesk, garantimos uma interface intuitiva, gerenciamento robusto e segurança. Contate-nos para otimizar sua presença online e elevar a eficiência do seu negócio.</p>
                                    </h6>
                                </div>
                                <div className="buttonHosting col-md-3">
                                    <Button href="https://wa.me/message/2VVAKHQJO6E5L1" className="button-outline text-uppercase">Solicitar orçamento</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            </Container>
            {/* // Recursos */}
            <Container className="mt-5 mb-5">
            <div className="text-center mt-5 mb-5">
                <h2 className="fw-bolder">Especificações técnicas completas</h2>
                <h5 className="fw-normal text-secondary">Recursos avançados de Hospedagem Plesk.</h5>
            </div>
            <Row>
                {RecursosItem.map((recursos, index) => (
                <Col key={index} className="col-xl-3 col-lg-3 col-md-6">
                    <ListGroup className="mt-3 text-start">
                        {recursos.map((subItem, subIndex) => (
                            <ListGroupItem key={subIndex} className="color-listgroup">
                            {subItem.icon} {subItem.name}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Col>
                ))}
            </Row>
            </Container>
            <div className="bg-secondary-n p-1">
                <Container className="mt-4 mb-4">
                    <Row>
                        <Col md={7} className="d-flex align-items-center">
                            <h5 className="text-white fw-bolder m-0">Hospedagem confiável, segura, altamente disponível e descomplicada</h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="outline-light" href="#">Inscreva-se agora</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}