import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Offer() {
    return(
        <>
            <div className="offer">
                <Container className="text-center">
                    🔥 Novos serviços de API: <Link className="text-white text-decoration-none fw-semibold" to="/apis">Confira agora mesmo</Link>
                </Container>
            </div>
        </>
    )
}