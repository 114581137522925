import React, { useState } from "react";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Image,
    Button,
    Carousel,
    CarouselItem,
    CarouselCaption,
    ListGroup,
    ListGroupItem,
    CardHeader,
    Table,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

// Images
import Hosting from "../../../assets/icon-shared-hosting.svg";
import Server from "../../../assets/icon-vps-hosting.svg";
import SharedImage from "../../../assets/dedicated-server-hosting.svg";
import SharedHosp from "../../../assets/shared-hosting-hostop.svg";

const Produtos = [
    {
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        icon: SharedHosp,
        style: "d-none",
        border: "",
        bordername: "Mais Popular",
        name: "DEDICADO ULTRA-9",
        description: "Processador Intel® Xeon® E3-1245v5",
        value: "650,00",
    },
    {
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        icon: Hosting,
        style: "d-block",
        border: "border-outline",
        bordername: "Mais Popular",
        name: "DEDICADO ULTRA-10",
        description: "Processador Intel® Xeon® E 2136",
        value: "950,00",
    },
    {
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        icon: Server,
        style: "d-none",
        border: "",
        bordername: "A POTÊNCIA QUE VOCÊ PRECISA!",
        name: "DEDICADO ULTRA-11",
        description: "Processador Intel® Xeon® D 1521",
        value: "1.070,00",
    },
];

const ListHospedagem = [
    [
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>Intel Xeon E3-1245v5</b> - <b>4c/8t</b> - <b>3.5GHz/3.9GHz</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "De <b>32 GB</b> a <b>64 GB</b> DDR4 ECC",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "A partir de <b>100Mbps</b> a <b>250Mbps</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "Taxas de instalação: <b>Livre</b> ",
            tooltip: "As taxas de configuração associadas ao seu servidor dedicado são dispensadas quando você escolhe qualquer opção de compromisso durante a finalização da compra.",
            display: "d-block"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>Proteção DDoS</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "Tráfego <b>ilimitado</b>",
            tooltip: "",
            display: "d-none"
        }
    ],
    [
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>Intel Xeon-E 2136</b> - <b>6c/12t</b> - <b>3.3GHz/4.5GHz</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "De <b>32 GB</b> a <b>128 GB</b> DDR4 ECC",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>500Mbps</b> - Largura de banda privada",
            tooltip: "A partir de 100 Mbps garantidos",
            display: "d-block"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "Taxas de instalação: <b>Livre</b> ",
            tooltip: "As taxas de configuração associadas ao seu servidor dedicado são dispensadas quando você escolhe qualquer opção de compromisso durante a finalização da compra.",
            display: "d-block"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>Proteção DDoS</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "Tráfego <b>ilimitado</b>",
            tooltip: "",
            display: "d-none"
        }
    ],
    [
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>AMD Ryzen 5 3600X</b> - <b>6c/12t</b> - <b>3.8GHz/4.4GHz</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "De <b>32 GB</b> a <b>64 GB</b> DDR4 ECC",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>250 Mbps</b> (burst <b>500 Mbps</b>)",
            tooltip: "A partir de 100 Mbps garantidos",
            display: "d-block"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "Taxas de instalação: <b>Livre</b> ",
            tooltip: "As taxas de configuração associadas ao seu servidor dedicado são dispensadas quando você escolhe qualquer opção de compromisso durante a finalização da compra.",
            display: "d-block"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "<b>Proteção DDoS</b>",
            tooltip: "",
            display: "d-none"
        },
        {
            icon: <Icon.CheckLg className="iconCheck" />,
            name: "Tráfego <b>ilimitado</b>",
            tooltip: "",
            display: "d-none"
        }
    ],
];

const TableVPS = [
    { name: "Modelo - Nome", classe: "ocultar-cl" },
    { name: "Processador", classe: "" },
    { name: "Memória", classe: "" },
    { name: "Largura de Banda", classe: "ocultar-cl" },
    { name: "Preço", classe: "" },
    { name: "" },
];

const TableItem = [
    {
        name: "ULTRA-12",
        processador: "<b>Intel Xeon-D 2141I</b> - <b>8c/16t</b> - <b>2.2GHz/3GHz</b>",
        memoria: "De <b>32GB</b> a <b>128 GB</b> DDR4 ECC",
        largura: "<b>500Mbps</b>",
        price: "1.450,00",
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        details: [
            {
                name: "<b>Localização</b> <p></p> Global",
                processador: "<b>Frequência máxima</b> <p></p>3 GHz",
                memoria: "<b>Armazenar</b> <p></p>SSD NVMe, HDD SATA",
                largura: "<b>Largura privada</b> <p></p>A partir de <b>100 Mbps</b>",
                price: "Taxas de instalação: <b style='color: #be291e'>Livre</b>",
                link: "",
            },
        ]
    },
    {
        name: "ULTRA-13",
        processador: "<b>Intel Xeon-E 2288G</b> - <b>8c/16t</b> - <b>3,7GHz/5GHz</b>",
        memoria: "De <b>32 GB</b> a <b>128 GB</b> DDR4 ECC",
        largura: "<b>500Mbps</b>",
        price: "1.850,00",
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        details: [
            {
                name: "<b>Localização</b> <p></p> Global",
                processador: "<b>Frequência máxima</b> <p></p>5 GHz",
                memoria: "<b>Armazenar</b> <p></p>HDD SATA, SSD NVMe",
                largura: "<b>Largura privada</b> <p></p>A partir de <b>100 Mbps</b>",
                price: "Taxas de instalação: <b style='color: #be291e'>Livre</b>",
                link: "",
            },
        ]
    },
    {
        name: "ULTRA-14",
        processador: "<b>AMD Epyc 7371</b> - <b>16c/32t</b> - <b>3,1GHz/3,8GHz</b>",
        memoria: "De <b>128 GB</b> a <b>512 GB</b> DDR4 ECC",
        largura: "<b>500Mbps</b>",
        price: "2.650,00",
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        details: [
            {
                name: "<b>Localização</b> <p></p> Global",
                processador: "<b>Frequência máxima</b> <p></p>3.8 GHz",
                memoria: "<b>Armazenar</b> <p></p>HDD SATA, SSD NVMe",
                largura: "<b>Largura privada</b> <p></p>A partir de <b>100 Mbps</b>",
                price: "Taxas de instalação: <b style='color: #be291e'>Livre</b>",
                link: "",
            },
        ]
    },
    {
        name: "ULTRA-15",
        processador: "<b>Intel Xeon Silver 4214R</b> - <b>24c/48t</b> - <b>2,2GHz/3,5GHz</b>",
        memoria: "De <b>96 GB</b> a <b>384 GB</b> DDR4 ECC",
        largura: "<b>500Mbps</b>",
        price: "3.350,00",
        link: "https://financeiro.hostop.com.br/produtos.php?ID=5",
        details: [
            {
                name: "<b>Localização</b> <p></p> Global",
                processador: "<b>Frequência máxima</b> <p></p>3.5 GHz",
                memoria: "<b>Armazenar</b> <p></p>HDD SATA, SSD NVMe",
                largura: "<b>Largura privada</b> <p></p>A partir de <b>100 Mbps</b>",
                price: "Taxas de instalação: <b style='color: #be291e'>Livre</b>",
                link: "",
            },
        ]
    },
];

export default function Dedicado() {
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (index) => {
        setExpandedRow((prevRow) => (prevRow === index ? null : index));
    };
    return (
        <>
            <div style={{ height: "104px" }}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container className="productCarousel">
                    <Carousel>
                        <CarouselItem interval={3000}>
                            <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <p className="col-md-10 mn-1">
                                            HOSPEDAGEM DE SERVIDOR DEDICADO DE ÚLTIMA GERAÇÃO
                                        </p>
                                        <h1 className="text-uppercase fw-bold">
                                            SERVIDOR DEDICADO
                                        </h1>
                                        <p className="col-md-10">
                                            Obtenha sem esforço a potência e a flexibilidade que você
                                            precisa. Nossos servidores dedicados são construídos do
                                            zero usando todo o armazenamento SSD. Aproveite maior
                                            poder, flexibilidade e controle para seus sites.
                                        </p>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={SharedImage} width={350} />
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Preços de servidores dedicados</h2>
                    <h5 className="fw-normal text-secondary">
                        Servidor dedicado autogerenciado – perfeito para administradores de
                        sistema e desenvolvedores.
                    </h5>
                </div>
                <Row className="products-h">
                    {Produtos.map((item, index) => (
                        <Col key={index} className="col-md-4 text-center mb-5">
                            <Card className={item.border}>
                                <CardBody>
                                    <span className={`plan-title ${item.style}`}>
                                        {item.bordername}
                                    </span>
                                    <Image src={item.icon} width={"50"} />
                                    <h4 className="pt-3 fw-semibold">{item.name}</h4>
                                    <h6 className="pt-2 text-black-50">{item.description}</h6>
                                    <h7 className="pt-4 mb-3">A partir de:</h7>
                                    <h3 className="fw-color-blue fw-regular fs-7 mb-3">
                                        <b className="fs-2">R$ {item.value}</b>/mês
                                    </h3>
                                    <ListGroup className="mt-3 text-start">
                                        {ListHospedagem[index].map((subItem, subIndex) => (
                                            <ListGroupItem key={subIndex} className="color-listgroup">
                                                {subItem.icon} <span dangerouslySetInnerHTML={{ __html: subItem.name }} />
                                                <OverlayTrigger key={subIndex} placement="right" overlay={<Tooltip id={`tooltip-${subIndex}`}>{subItem.tooltip}</Tooltip>}>
                                                    <button className={`popover-btn ${subItem.display}`}>?</button>
                                                </OverlayTrigger>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>

                                    <Button
                                        className="button-outline fw-semibold mt-4"
                                        href={item.link}
                                    >
                                        Configurar
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5">
                    <h2 className="fw-bolder">Quando você espera desempenho</h2>
                    <h5 className="fw-normal text-secondary">
                        Máquinas virtuais com um equilíbrio saudável de memória e
                        hyper-threads de computação dedicados dos melhores processadores da
                        categoria. Projetado para a mais ampla variedade de cargas de
                        trabalho convencionais ou de produção, incluindo hospedagem de
                        aplicativos Web, sites de comércio eletrônico, bancos de dados de
                        médio porte e aplicativos empresariais.
                    </h5>
                </div>
                <Row>
                    <Card className="p-0">
                        <CardHeader className="card-header-servidor">
                            <h5 className="fw-semibold">
                                Hardware aprimorado para nós empresariais da Dell
                            </h5>
                            <p className="m-0">
                                Todo o nosso hardware é Dell Enterprise com armazenamento
                                raid10, redundância dupla e energia, rede dupla redundância.
                            </p>
                        </CardHeader>
                        <CardBody className="p-0">
                            <Table className="m-0" responsive="sm">
                                <thead>
                                    <tr>
                                        {TableVPS.map((item, index) => (
                                            <th className={`bg-primarys text-white ${item.classe}`} key={index}>
                                                {item.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="tablebody">
                                {TableItem.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr
                                            onClick={() => handleRowClick(index)}
                                            className={expandedRow === index ? "expanded" : ""}
                                        >
                                            <>
                                                <td className="ocultar-cl">{item.name}</td>
                                                <td><span dangerouslySetInnerHTML={{ __html: item.processador }} /></td>
                                                <td><span dangerouslySetInnerHTML={{ __html: item.memoria }} /></td>
                                                <td className="ocultar-cl"><span dangerouslySetInnerHTML={{ __html: item.largura }} /></td>
                                                <td className="price-td fw-semibold">
                                                    R$ {item.price}
                                                    <span className="text-secondary fw-normal fs-7">
                                                        /mês
                                                    </span>
                                                </td>
                                                <td>
                                                    <Button className="button-buy fw-semibold" href={item.link}>
                                                        Configurar
                                                    </Button>
                                                </td>
                                            </>
                                        </tr>
                                        {expandedRow === index && (
                                            <>
                                                {item.details.map((subItem, subIndex) => (
                                                    <tr className="tabletwo" key={subIndex}>
                                                        <td className="text-center nametd ocultar-cl" dangerouslySetInnerHTML={{ __html: subItem.name }} />
                                                        <td className="text-center processtd" dangerouslySetInnerHTML={{ __html: subItem.processador }} />
                                                        <td className="text-center ramtd" dangerouslySetInnerHTML={{ __html: subItem.memoria }} />
                                                        <td className="text-center larguratd" dangerouslySetInnerHTML={{ __html: subItem.largura }} />
                                                        <td className="text-center precotd ocultar-cl" style={{ fontSize: '11px' }} dangerouslySetInnerHTML={{ __html: subItem.price }} />
                                                        <td className="text-center configurartd ocultar-cl"></td>
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            <div className="bg-secondary-n p-1">
                <Container className="mt-4 mb-4">
                    <Row>
                        <Col md={7} className="d-flex align-items-center">
                            <h5 className="text-white fw-bolder m-0">
                                Hospedagem confiável, segura, altamente disponível e
                                descomplicada
                            </h5>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button variant="outline-light" href="/hospedagem">
                                Inscreva-se agora
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}