import React from "react";
import Navigation from "./Navbar.tsx";

export default function FixedTop() {
    return(
        <>
            <div className="fixed-top">
                <Navigation />
            </div>
        </>
    )
}