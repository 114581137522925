import React from "react";
import {  Card, CardBody, Container, Row, Col, Image, Button, Carousel, CarouselItem, CarouselCaption, CardHeader, CardFooter, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

// Images
import SharedImage from "../../../assets/apisvg2.svg";

// Images Integração
import FacebookAPI from "../../../assets/facebook-approved-api-apps.png";
import InstagramAPI from "../../../assets/instagram-approved-api-apps.png";
import GoogleMapsAPI from "../../../assets/maps-approved-api-apps.png";
import YoutubeAPI from "../../../assets/youtube-approved-api-apps.png";
import MelhorEnvioAPI from "../../../assets/Melhor-Envio-publieditorial-maio.png";
import CorreiosAPI from "../../../assets/apicorreios.png";
import WhatsAppAPI from "../../../assets/whatsappapi.png";
import TwitterAPI from "../../../assets/xapi.png";
import TiktokAPI from "../../../assets/tiktokapi.png";

const Produtos = [
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: GoogleMapsAPI, name: 'Google Maps API', categoria: 'Serviços', description: 'Essa API possibilita a utilização de diversos serviços, como visualização de mapas, navegação, geocodificação (transformação de endereços em coordenadas geográficas) e reverse geocodificação (obtenção de endereços a partir de coordenadas).', valor: '3.000' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: FacebookAPI, name: 'Facebook API', categoria: 'Serviços', description: 'Os aplicativos de API aprovados pelo Facebook fornecem uma maneira segura e confiável para você acessar as APIs do Facebook e criar seus próprios Hostop que se integram à plataforma do Facebook.', valor: '8.999' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: InstagramAPI, name: 'Instagram API', categoria: 'Serviços', description: 'Os aplicativos API aprovados pelo Instagram fornecem uma maneira segura e confiável para você acessar as APIs do Instagram e criar seus próprios Hostop que se integram à plataforma do Instagram.', valor: '11.999' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: YoutubeAPI, name: 'Youtube API', categoria: 'Serviços', description: 'As APIs do YouTube fornecem acesso autorizado a vários recursos e funcionalidades do YouTube, como upload de vídeos, gerenciamento de contas de usuário e integração.', valor: '6.000' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: MelhorEnvioAPI, name: 'Melhor Envio API', categoria: 'Serviços', description: 'O Melhor Envio possui uma API (Application Programming Interface) pública que permite a integração com qualquer loja virtual.', valor: '5.500' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: CorreiosAPI, name: 'Correios API', categoria: 'Serviços', description: 'API de correios possibilita cálculo de frete, rastreamento de encomendas, consulta de CEP, geração de etiquetas, estimativas de prazo de entrega e integração com e-commerce para otimizar processos logísticos.', valor: '3.500' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: WhatsAppAPI, name: 'WhatsApp API', categoria: 'Serviços', description: 'Permite que você integre a ferramenta de marketing do WhatsApp em suas aplicações de software e possibilita que seu próprio aplicativo envie e receba mensagens pelo WhatsApp.', valor: '9.999' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: TwitterAPI, name: 'X API', categoria: 'Serviços', description: 'Os módulos do X ajudam empresas a otimizar presença, economizar tempo, aprimorar estratégia de mídia social e aumentar interação com o público.', valor: '9.499' },
   { link: 'https://financeiro.hostop.com.br/produtos.php?ID=8', imagem: TiktokAPI, name: 'Tiktok API', categoria: 'Serviços', description: 'A Ferramenta de Publicação Automática para o TikTok simplifica o planejamento e automação de postagens no TikTok.', valor: '8.999' },
];

export default function Api() {
    return(
        <>
            <div style={{height: '104px'}}></div>
            <section>
                <div className="bgcontainer"></div>
                <Container className="productCarousel">
                    <Carousel>
                        <CarouselItem interval={ 3000 }>
                            <Row className="row d-flex align-items-center justify-content-center p-5-carousel">
                                <Col className="col col-md-6 d-grid justify-content-center">
                                    <CarouselCaption>
                                        <p className="col-md-10 mn-1">INTEGRAÇÕES, API E CONEXÃO COM WEBSERVICES</p>
                                        <h1 className="text-uppercase fw-bold">INTEGRAÇÕES API</h1>
                                        <p className="col-md-10">Automatize seus processos com integrações desenvolvidas sob medida.</p>
                                    </CarouselCaption>
                                </Col>
                                <Col className="d-flex justify-content-center">
                                    <Image src={SharedImage} width={350} />
                                </Col>
                            </Row>
                        </CarouselItem>
                    </Carousel>
                </Container>
            </section>
            <Container className="mt-5 mb-5">
                <div className="text-center mt-5 mb-5 px-15 px-100">
                    <h2 className="fw-bolder">Integrações API</h2>
                    <h5 className="fw-normal text-secondary px-100">Integrações de API permitem a comunicação entre diferentes sistemas e aplicativos, facilitando a troca de dados e a automação de processos.</h5>
                </div>
                <Row className="products-h">
                {Produtos.map((item, index) => (
                    <Col key={index} className="col-md-4 mb-5">
                    <Card>
                        <CardHeader className="p-0 m-0">
                            <div className="image-header" style={{backgroundImage: `url(${item.imagem})`}}>
                                <Badge className="req-success">Sem limite de requisição</Badge>
                            </div>
                        </CardHeader>
                        <CardBody className="servicecard">
                            <h5 className="fw-semibold">{item.name}</h5>
                            <h6><Icon.Layers /> <span className="fs-72">{item.categoria}</span></h6>
                            <p className="text-secondary fs-71">{item.description}</p>
                        </CardBody>
                        <CardFooter>
                            <Row className="align-items-center d-flex">
                                <Col>
                                    <div className="pricetag">
                                        R$ {item.valor},00
                                        <OverlayTrigger placement="right" overlay={<Tooltip id="Pagamento único">Pagamento único</Tooltip>}>
                                            <button className={`popover-btn api-popover`}>?</button>
                                        </OverlayTrigger>
                                    </div>
                                </Col>
                                <Col className="justify-content-end d-flex">
                                    <Button className="button-outline" href={item.link}>Comprar agora</Button>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Card>
                    </Col>
                ))}
                </Row>
            </Container>
        </>
    )
}