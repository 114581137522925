import React from "react"
import { Col, Container, NavLink, Row } from "react-bootstrap"

const Produtos = [
    { name: "Hospedagem cPanel", href: "/hospedagem-cpanel" },
    { name: "Hospedagem Plesk", href: "/hospedagem-plesk" },
    { name: "Servidores VPS", href: "/servidores" },
    { name: "Planos de Revendedor", href: "/revenda" },
    { name: "Servidores dedicados", href: "/dedicados" }
]

const Servicos = [
    { name: "Proteção DDOS Premium", href: "/protecao" },
    { name: "Streaming de Rádio", href: "/" },
    { name: "Serviços API", href: "/apis" }
]

const MyAccount = [
    { name: "Minha Conta", href: "https://financeiro.hostop.com.br/login.php" },
    { name: "Criar Conta", href: "https://financeiro.hostop.com.br/registrar.php" }
]

const Suporte = [
    { name: "Abrir Ticket", href: "https://financeiro.hostop.com.br/contato.php" },
    { name: "Contate-nos", href: "https://financeiro.hostop.com.br/contato.php" },
    { name: "Nosso Blog", href: "https://financeiro.hostop.com.br/basedeconhecimento.php" }
]

export default function Footer() {
    return(
        <>
            <Container className="mt-5">
                <Row className="mb-3">
                    <Col xs={6} md={3} className="mb-3">
                        <h5 className="text-white">Produtos</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                {Produtos.map((item, index) => (
                                    <NavLink className="padding-nav-item fs-7" href={item.href}>{ item.name}</NavLink>
                                ))}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3}>
                        <h5 className="text-white">Serviços</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                {Servicos.map((item, index) => (
                                    <NavLink className="padding-nav-item fs-7" href={item.href}>{ item.name}</NavLink>
                                ))}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} className="mb-3">
                        <h5 className="text-white">Minha Conta</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                {MyAccount.map((item, index) => (
                                    <NavLink className="padding-nav-item fs-7" href={item.href}>{ item.name}</NavLink>
                                ))}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} className="mb-3">
                        <h5 className="text-white">Centro de Ajuda</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                {Suporte.map((item, index) => (
                                    <NavLink className="padding-nav-item fs-7" href={item.href}>{ item.name}</NavLink>
                                ))}
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <h7 className="text-white text-center p-3 border-top">Copyright © 2019 - 2024 Hostop. Todos os direitos reservados.</h7>
                </Row>
            </Container>
        </>
    )
}