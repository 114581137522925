import React from "react";
import { Container, Navbar, NavbarBrand, Image, Nav, NavbarToggle, NavbarCollapse, NavDropdown, Button, Badge } from "react-bootstrap";

import Offer from "./Offer.tsx";
import { NavLink } from "react-router-dom";

// Images
import logo from "../../../assets/logocolor.png";
import Hospedagem from "../../../assets/shared-hosting-menu.svg";
import Reseller from "../../../assets/reseller-hosting.svg";
import Server from "../../../assets/vps-cloud-hostop.svg";
import Dedicated from "../../../assets/vps-servers-hostop.svg";
import Protecao from "../../../assets/semi-dedicated-hostop.svg";
import SubmitTicket from "../../../assets/customer-service-hostop.svg";
import HospedagemPlesk from "../../../assets/nuvem-hosting.svg";
import ApiIcon from "../../../assets/iconeapi.svg";
import RadioIcon from "../../../assets/iconeradio.svg";

const Navigator = [
    { href: '/hospedagem-cpanel', icon: Hospedagem, name: 'Hospedagem cPanel', description: 'Rápido, confiável e seguro', display: "d-inline", namebadge: '', bg: ''},
    { href: '/hospedagem-plesk', icon: HospedagemPlesk, name: 'Hospedagem Plesk Empresarial', description: 'Adote uma abordagem profissional', display: "d-inline", namebadge: '', bg: ''},
    { href: '/revenda', icon: Reseller, name: 'Revenda de Hospedagem', description: 'Monte seu negócio de hospedagem', display: "d-inline", namebadge: '', bg: ''}
    // { icon: Hospedagem, name: 'Streaming de Rádio', description: 'Web Hosting Reseller 3', display: "d-inline", namebadge: 'Popular', bg: 'primary' }
];

const Servidores = [
    { href: '/servidores', icon: Server, name: 'Servidores VPS', description: 'Controle total com acesso root total', display: "d-inline", namebadge: 'Popular', bg: 'success'},
    { href: '/dedicados', icon: Dedicated, name: 'Servidores dedicados', description: 'Hospedado com a melhor infraestrutura da categoria e largura de banda premium', display: "d-inline", namebadge: '', bg: ''}
];

const Servicos = [
    { href: '/protecao', icon: Protecao, name: 'Proteção DDOS Premium', description: 'Proteção DDoS em tempo real e sempre ativa, sem tempo de inatividade', display: "d-inline", namebadge: '', bg: ''},
    { href: '/', icon: RadioIcon, name: 'Streaming de Rádio', description: 'Streaming de áudio para rádio', display: "d-inline", namebadge: '', bg: ''},
    { href: '/apis', icon: ApiIcon, name: 'Serviços API', description: 'Conheça nossos serviços de Integração de API', display: "d-inline", namebadge: 'Novo', bg: 'danger'}
];

const Ticket = [
    { href: 'https://financeiro.hostop.com.br/contato.php', icon: SubmitTicket, name: 'Ticket de Suporte', description: 'À disposição para ajudar!', display: 'd-inline', namebadge: '', bd: '' }
]

export default function Navigation() {
    return(
        <>
            <Offer />
            <Navbar className="bg-light box-shadow-sm" expand="lg">
                <Container>
                    <NavbarBrand href="/">
                        <Image src={logo} width={'100'} fluid />
                    </NavbarBrand>
                    <NavbarToggle aria-controls="navbar-nav" />
                    <NavbarCollapse id="navbar-nav">
                        <Nav className="me-auto" />
                        <Nav>
                            <NavDropdown className="m-2"title="Hospedagem">
                                {Navigator.map((item, index) => (
                                    <NavDropdown.Item className="rounded" key={index} href={ item.href }>
                                        <div className="d-flex gap-3 align-items-center">
                                            <div className="icon-dropdown">
                                                <Image src={item.icon} alt={item.name} width={35}/>
                                            </div>
                                            <div className="d-flex flex-column lh-sm flex-column-break">
                                                <div>{item.name} <Badge className={item.display} bg={item.bg}>{item.namebadge}</Badge></div>
                                                <div className="description text-black-50">{item.description}</div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <NavDropdown className="m-2"title="Servidores">
                                {Servidores.map((item, index) => (
                                    <NavDropdown.Item className="rounded" key={index} href={ item.href }>
                                        <div className="d-flex gap-3 align-items-center">
                                            <div className="icon-dropdown">
                                                <Image src={item.icon} alt={item.name} width={35}/>
                                            </div>
                                            <div className="d-flex flex-column lh-sm flex-column-break">
                                                <div>{item.name} <Badge className={item.display} bg={item.bg}>{item.namebadge}</Badge></div>
                                                <div className="description text-black-50">{item.description}</div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <NavDropdown className="m-2"title="Serviços">
                                {Servicos.map((item, index) => (
                                    <NavDropdown.Item className="rounded" key={index} href={ item.href }>
                                        <div className="d-flex gap-3 align-items-center">
                                            <div className="icon-dropdown">
                                                <Image src={item.icon} alt={item.name} width={35}/>
                                            </div>
                                            <div className="d-flex flex-column lh-sm flex-column-break">
                                                <div>{item.name} <Badge className={item.display} bg={item.bg}>{item.namebadge}</Badge></div>
                                                <div className="description text-black-50">{item.description}</div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <NavDropdown className="m-2"title="Suporte">
                                {Ticket.map((item, index) => (
                                    <NavDropdown.Item className="rounded" key={index} href={ item.href }>
                                        <div className="d-flex gap-3 align-items-center">
                                            <div className="icon-dropdown">
                                                <Image src={item.icon} alt={item.name} width={35}/>
                                            </div>
                                            <div className="d-flex flex-column lh-sm flex-column-break">
                                                <div>{item.name}</div>
                                                <div className="description text-black-50">{item.description}</div>
                                            </div>
                                        </div>
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <NavLink className="d-flex align-items-center text-decoration-none" to="https://financeiro.hostop.com.br/login.php">
                                <Button className="d-flex btn-prismy mx-2">Minha conta</Button>
                            </NavLink>
                        </Nav>
                    </NavbarCollapse>
                </Container>
            </Navbar>
        </>
    )
}