import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/views/layout/Header.tsx";
import Home from "./components/views/pages/Home.tsx";
import FixedTop from "./components/views/layout/FixedTop.tsx";
import Vendors from "./components/views/pages/Vendors.tsx";
// import Security from "./components/views/pages/Security.tsx";
import Success from "./components/views/pages/Success.tsx";
import Footer from "./components/views/layout/Footer.tsx";
import Account from "./components/views/pages/Account.tsx";
import Hospedagem from "./components/views/pages/Hospedagem.tsx";
import HospedagemPlesk from "./components/views/pages/HospedagemPlesk.tsx";
import Revenda from "./components/views/pages/Revenda.tsx";
import Servidor from "./components/views/pages/Servidor.tsx";
import Dedicado from "./components/views/pages/Dedicado.tsx";
import Protection from "./components/views/pages/Protection.tsx";
import Api from "./components/views/pages/Api.tsx";
import Datacenter from "./components/views/pages/Datacenter.tsx";

function App() {
  return (
    <Router>
      <FixedTop />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Success />
              <div className="bg-secondary-s p-1">
                <Home />
              </div>
              <Vendors />
              <div className="bg-secondary-n p-1">
                <Account />
              </div>
            </>
          }
        />

        <Route path="/hospedagem-cpanel" element={<Hospedagem />} />
        <Route path="/hospedagem-plesk" element={<HospedagemPlesk />} />
        <Route path="/revenda" element={<Revenda />} />
        <Route path="/servidores" element={<Servidor />} />
        <Route path="/dedicados" element={<Dedicado />} />
        <Route path="/protecao" element={<Protection />} />
        <Route path="/apis" element={<Api />} />
        <Route path="/datacenter" element={<Datacenter />} />
      </Routes>
      <div className="bg-secondary-t p-1">
        <Footer />
      </div>
    </Router>
  );
}

export default App;
